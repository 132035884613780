import * as React from "react";
import "../../Components/Contact/Contact.scss";
import Header from "../../Components/Header/Header";
import { Col, Container, Row } from "react-bootstrap";
import ContactForm from "../../Components/Contact/ContactForm";
import { graphql, useStaticQuery } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Partnership from "../../Components/Partnership/Partnership";
import Footer from "../../Components/Footer/Footer";
import Seo from "../../Components/Seo/Seo";
import HamburgerMenu from "../../Components/HamburgerMenu/HamburgerMenu";

// markup
const ContactPage = () => {
  const contactPageData = useStaticQuery(graphql`
    query {
      contentfulContactPage(node_locale: { eq: "en-US" }) {
        titolo
        sezioneInfo {
          raw
        }
        sezioneSupporto {
          raw
        }
        sezioneIndirizzo {
          raw
        }
        labelCampoAzienda
        labelCampoEmail
        labelCampoMessaggio
        eMailDestinatario
        labelCheckboxPrivacy
        enter
      }
    }
  `);

  return (
    <div className="contact">
      <Seo locale="en-US" page="contact us" />
      <Header lang="en-US" />
      <HamburgerMenu />
      <section>
        <Container>
          <Row>
            <Col lg="6" className="order-2 order-lg-1">
              <div className="heading d-none d-lg-block">
                <h1>{contactPageData.contentfulContactPage.titolo}</h1>
              </div>
              <div className="content">
                <div className="address-item">
                  {documentToReactComponents(
                    JSON.parse(
                      contactPageData.contentfulContactPage.sezioneInfo.raw
                    )
                  )}
                </div>
                <div className="address-item">
                  {documentToReactComponents(
                    JSON.parse(
                      contactPageData.contentfulContactPage.sezioneSupporto.raw
                    )
                  )}
                </div>
                <div className="address-item">
                  {documentToReactComponents(
                    JSON.parse(
                      contactPageData.contentfulContactPage.sezioneIndirizzo.raw
                    )
                  )}
                </div>
              </div>
            </Col>
            <Col lg="6" className="order-1 order-lg-2">
              <div className="heading d-block d-lg-none">
                <h1>{contactPageData.contentfulContactPage.titolo}</h1>
              </div>
              <div className="form-contact">
                <ContactForm
                  toEmail={
                    contactPageData.contentfulContactPage.eMailDestinatario
                  }
                  companyLabel={
                    contactPageData.contentfulContactPage.labelCampoAzienda
                  }
                  emailLabel={
                    contactPageData.contentfulContactPage.labelCampoEmail
                  }
                  messageLabel={
                    contactPageData.contentfulContactPage.labelCampoMessaggio
                  }
                  privacyLabel={
                    contactPageData.contentfulContactPage.labelCheckboxPrivacy
                  }
                  buttonLabel={contactPageData.contentfulContactPage.enter}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Partnership lang="en-US" addTopBorder={true} />
      <Footer lang="en-US" />
    </div>
  );
};

export default ContactPage;
